<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-xxl">
      <!--begin::Contact-->
      <div class="card p-0">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column p-lg-17">
          <!--begin::Row-->
          <div class="d-flex w-100 justify-content-center mb-10">
            <h1>Disclaimer</h1>
          </div>
          <div class="d-flex w-100 flex-column">
            <p>
              Disclaimer for Tenant Hub Pty Ltd ACN ____________ and its related
              entities ‘Tenanthub.au’
            </p>

            <ol>
              <li>
                The information provided on this Tenanthub.au website has been
                produced for our members and tenants convenience and with
                reasonable care and may not contain answers to all of your
                questions or searches. Tenanthub.au makes no warranties about
                the accuracy or completeness of any information contained or
                omitted on this website, or its accessibility at any time.
              </li>
              <li>
                Tenanthub.au, including it’s directors, affiliates, officers,
                employees, agents, contractors, successors and assigns, will not
                accept any liability for any loss, damage or other injury
                resulting from its use.
              </li>
              <li>
                You acknowledge that Tenanthub.au made not warranties that the
                services and/or information will be error free.
              </li>
              <li>
                Links to other websites are provided for your convenience and
                Tenanthub.au is not responsible for the information contained on
                those website. Websites which you are transferred to are not
                controlled by Tenanthub.au and Tenanthub.au is not responsible,
                and does not accept any liability, for the information contained
                on those websites. The provision of a link to another website
                does not constitute and endorsement or approval of that website,
                or any products or services offered on that website, by
                Tenanthub.au. Tenanthub.au will not accept any liability for the
                use of those links to connect to websites that are not under its
                control.
              </li>
              <li>
                Tenanthub.au, its directors, affiliates, officers, employees,
                agents, contractors, successors and assigns do not accept any
                liability:
                <ol style="list-style: none">
                  <li>
                    a. For any property related or investment decisions made
                    based on this information. This website does not constitute
                    financial or property advice and should not be taken as
                    such. Tenanthub.au insists that you should obtain
                    professional advice before proceeding with any investment
                    and tenancy related decisions.
                  </li>
                  <li>
                    b. For any damages or losses whatsoever, arising out of, or
                    in any way related to, the use of this site and any other
                    sites linked to this site.
                  </li>
                </ol>
              </li>
              <li>
                The limitation on liability contained in paragraph 5 applies to
                direct, in direct, consequential, special, punitive or other
                damages that you or others may suffer, as well as damages for
                loss of profit, business interruption or the loss of data or
                information.
              </li>
              <li>
                Tenanthub.au holds the copyright to this website. You are not
                permitted to reproduce or alter this website, or any copy of it,
                for any other purpose without the prior express written consent
                of Tenanthub.au.
              </li>
              <li>
                You agree that Tenanthub.au will not be liable or responsible
                for any failure in or delay to the provision of the Servies or
                Information or in Tenanthub.au complying with its obligation
                under the terms and conditions where such failure or delay has
                arisen as a direct or indirect result of:
                <ol style="list-style: none">
                  <li>
                    a. Fire, earthquake, storm, flood hurricane, inclement
                    weather or other act of God, war, terrorism, explosion,
                    sabotage, industrial accident or any an industrial strike;
                  </li>
                  <li>
                    b. Denial of service attacks, telecommunication failure,
                    hardware failure or the failure of software provided by a
                    third party to function in accordance with its
                    specifications;
                  </li>
                  <li>
                    c. A significant demand being placed on tenant.com.au
                    services which is above the usual level of demand and which
                    results in a failure of tenant.com.au software and hardware
                    to function correctly;
                  </li>
                  <li>d. The failure of any third party;</li>
                  <li>
                    e. Any other circumstance or event similar to the above
                    which is beyond the reasonable control of tenant.com.au.
                  </li>
                </ol>
              </li>
              <li>
                Tenanthub.au regards your privacy as important and will take all
                reasonable steps to ensure that if you have subscribed to
                receive notifications or updates from tenanthub.au; your email
                address and information will remain confidential.
              </li>
              <li>
                Tenanthub.au reserves the right to make changes, modifications,
                additions, deletions and corrections to the website, at any time
                and without notice.
              </li>
              <li>
                Tenanthub.au does not accept any liability for any financial
                decision and tenancy decision made by you on the basis of
                information contained within the website.
              </li>
            </ol>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Contact-->
    </div>
    <!--end::Content container-->

    <!-- <GoogleRecaptcha /> -->
  </div>
</template>
<style>
li {
  margin-top: 10px;
}
</style>
